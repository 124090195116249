import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const language = localStorage.getItem('language') || 'en';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            ru: {
                translation: {
                    home: 'Главная',
                    profile: 'Профиль',
                    register: 'Регистрация',
                    login: 'Вход',
                    switchLanguage: 'Переключить на английский',
                    SwitchTo: 'Переключить тему',
                    email: 'Электронная почта',
                    password: 'Пароль',
                    username: 'Имя пользователя',
                    confirmPassword: 'Подтверждение пароля',
                    UserSuccessfullyRegistered: 'Пользователь успешно создан!',
                    Success: 'Успешно!',
                    hello: 'Добро пожаловать',
                    CreateNewForm: '+ Создать новую форму',
                    FormName: 'Название формы',
                    Close: 'Закрыть',
                    SelectaSurveyTemplateorCreateaNewOne: 'Выберите шаблон опроса или создайте новый',
                    Proceed: 'Продолжить',
                    Login_to_create: 'Зарегестрируйтесь, чтобы продолжить',
                    EventRegistration: 'Регистрация на мероприятие',
                    Formforeventregistration: 'Форма для регистрации на мероприятие.',
                    CustomerFeedback: 'Отзыв клиента',
                    Formforcollectingcustomerfeedback: 'Форма для сбора отзывов клиентов.',
                    ContactInformation: 'Контактная информация',
                    Formforcollectingcontactinfo: 'Форма для сбора контактной информации.',
                    error_generic: 'Пользователь не найден или пароль неверный.',
                    error_title: 'Ошибка',
                    try_again: 'Попробуйте снова',
                    create_form: 'Создайте форму',
                    form_title: 'Название формы',
                    form_description: 'Описание формы',
                    questions: 'Вопросы',
                    question_type: 'Тип вопроса',
                    question_text: 'Текст вопроса',
                    add_question: 'Добавить вопрос:',
                    save_form: 'Сохранить форму',
                    remove_question: 'Удалить вопрос',
                    Create_New_Form: 'Создать новую форму',
                    Start_from_scratch: 'Начать создавать свою форму.',
                    single_choice: 'Вопрос с одним ответом',
                    multiple_choice: 'Вопрос с многими ответами',
                    short_answer: 'Вопрос к коротким ответом',
                    long_answer: 'Вопрос с многострочным ответом',
                    question: 'Вопрос',
                    options: 'Варианты ответов',
                    Question_Text: 'Текст вопроса',
                    Add_Option: 'Добавить свойство',
                    Logout: 'Выход',
                    Your_Forms: 'Ваши формы опросов',
                    Name: 'Название',
                    Edit: 'Редактировать',
                    Description: 'Описание',
                    form_created_successfully: 'Форма успешно создана',
                    close: 'Закрыть',
                    success: 'Успешно!',
                    Questions: 'Вопросы',
                    No_questions: 'Нет вопросов',
                    fetch_forms_error: 'Ошибка получения форм',
                    Create: 'Создать',
                    Create_The_Same_Form: 'Создать на основе шаблона',
                    Search_by_name: 'Поиск по названию',
                    Edit_Form: 'Редактировать форму',
                    Save_Edit: 'Сохранить изменения',
                    Loading: 'Загрузка...',
                    No_forms_available_for_the_user: 'Нет форм созданных вами',
                    Create_your_first_form: 'Создайте свою первую форму',
                    Delete: 'Удалить',
                    Delete_Profile: 'Удалить профиль',
                    Are_you_sure_you_want_to_delete_your_profile: 'Вы уверены, что хотите удалить ваш профиль?',
                    delete_profile_error: 'Ошибка удаления профиля',
                    Option: 'Вариант ответа',
                    Integrate_with_Salesforce: 'Интеграция данных с Salesforce',
                    integrationMissingUserData: 'Имя пользователя или электронная почта отсутствуют.',
                    integrationAccountCreated: 'Аккаунт успешно создан в Salesforce!',
                    integrationDuplicateAccount: 'Аккаунт с такими данными уже существует в Salesforce.',
                    integrationCreationError: 'Ошибка при создании аккаунта в Salesforce',
                    integrationUnknownError: 'Не удалось создать аккаунт в Salesforce. Пожалуйста, попробуйте позже.',
                    integrationServerError: 'Произошла ошибка при интеграции с Salesforce. Пожалуйста, попробуйте позже.',
                    Create_Support_Ticket: 'Обратиться в поддержку',
                    Priority: 'Приоритет',
                    High: 'Высокий',
                    Low: 'Низкий',
                    Average: 'Средний',
                    Enter_summary: 'Введите тему',
                    Submit: 'Отправить',
                    Support_tickets: 'Созданные обращения в поддержку',
                    No_tickets_found: 'Не найдено созданных обращений.',
                    Your_Support_Tickets: 'Ваши обращения в поддержку',
                    Ticket_created_successfully: 'Запрос успешно создан',
                    Error_creating_ticket: 'Ошибка в создании запроса',
                    Enter_description: 'Введите описание',
                    You_can_view_it_here: 'Вы можете посмотреть созданный запрос по ссылке',
                    Ticket_ID: 'ID обращения',
                    Summary: 'Тема обращения',
                    Status: 'Статус',
                    Link: 'Ссылка',
                    View_Ticket: 'Перейти к обращению',
                    Salesforce_Integration: 'Интеграция с Salesforce',
                    Phone_Number: 'Отправьте номер вашего телефона',
                    phoneNumberUpdated: 'Номер телефона отправлен',
                    phoneNumberUpdateFailed: 'Ошибка обновления номера телефона',
                    phoneNumberUpdateError: 'Ошибка обновления телефона',
                },
            },
            en: {
                translation: {
                    home: 'Home',
                    profile: 'Profile',
                    register: 'Register',
                    login: 'Login',
                    switchLanguage: 'Switch to Russian',
                    SwitchTo: 'Switch theme',
                    email: 'e-mail',
                    password: 'Password',
                    username: 'Username',
                    confirmPassword: 'Confirm password',
                    UserSuccessfullyRegistered: 'User successfully registered!',
                    Success: 'Success!',
                    hello: 'Hello',
                    CreateNewForm: '+ Create New Form',
                    FormName: 'Form Name',
                    Close: 'Close',
                    SelectaSurveyTemplateorCreateaNewOne: 'Select a Survey Template or Create a New One',
                    Proceed: 'Proceed',
                    Login_to_create: 'Login to create',
                    EventRegistration: 'Event Registration',
                    Formforeventregistration: 'Form for event registration.',
                    CustomerFeedback: 'Customer Feedback',
                    Formforcollectingcustomerfeedback: 'Form for collecting customer feedback.',
                    ContactInformation: 'Contact Information',
                    Formforcollectingcontactinfo: 'Form for collecting contact info.',
                    error_generic: 'User not found or password is incorrect.',
                    error_title: 'Error',
                    try_again: 'Try again',
                    create_form: 'Create form',
                    form_title: 'Form title',
                    form_description: 'Form description',
                    questions: 'Questions',
                    question_type: 'Question type',
                    question_text: 'Question text',
                    add_question: 'Add question:',
                    save_form: 'Save form',
                    remove_question: 'Remove question',
                    Create_New_Form: 'Create new form',
                    Start_from_scratch: 'Start from scratch.',
                    single_choice: 'Single choice',
                    multiple_choice: 'Multiple choice',
                    short_answer: 'Short answer',
                    long_answer: 'Long answer',
                    question: 'Question',
                    options: 'Options',
                    Question_Text: 'Question text',
                    Add_Option: 'Add option',
                    Logout: 'Logout',
                    Your_Forms: 'Your forms',
                    Name: 'Name',
                    Edit: 'Edit',
                    Description: 'Description',
                    form_created_successfully: 'Form created successfully',
                    close: 'Close',
                    success: 'Success!',
                    Questions: 'Questions',
                    No_questions: 'No questions!',
                    fetch_forms_error: 'Fetch forms error',
                    Create: 'Create',
                    Create_The_Same_Form: 'Create the same form',
                    Search_by_name: 'Search by name',
                    Edit_Form: 'Edit form',
                    Save_Edit: 'Save edit',
                    Loading: 'Loading...',
                    No_forms_available_for_the_user: 'No forms available for the user',
                    Create_your_first_form: 'Create your first form',
                    Delete: 'Delete',
                    Delete_Profile: 'Delete profile',
                    Are_you_sure_you_want_to_delete_your_profile: 'Are you sure you want to delete your profile?',
                    delete_profile_error: 'Delete profile error',
                    Option: 'Option',
                    Integrate_with_Salesforce: 'Integrate with Salesforce',
                    integrationMissingUserData: "Email isn't consist.",
                    integrationAccountCreated: "The account has been created successfully in Salesforce!",
                    integrationDuplicateAccount: "The account with such type of data already exists in Salesforce.",
                    integrationCreationError: "Error while creating account in Salesforce",
                    integrationUnknownError: "Can't create account in Salesforce. Try again later.",
                    integrationServerError: "Error while creating account in Salesforce. Try again later.",
                    Create_Support_Ticket: "Create support ticket",
                    Priority: "Priority",
                    High: "High",
                    Low: "Low",
                    Average: "Average",
                    Enter_summary: "Enter summary",
                    Summary: "Summary",
                    Submit: "Submit",
                    Support_tickets: "Support tickets",
                    No_tickets_found: "No tickets found.",
                    Your_Support_Tickets: "Your support tickets",
                    Ticket_created_successfully: "Ticket created successfully",
                    Error_creating_ticket: "Error creating ticket",
                    Enter_description: "Enter description",
                    You_can_view_it_here: "You can view it here",
                    Ticket_ID: "Ticket ID",
                    Status: "Status",
                    Link: "Link",
                    View_Ticket: "View ticket",
                    Salesforce_Integration: "Salesforce integration",
                    Phone_Number: "Sent your phone number",
                    phoneNumberUpdated: "Phone number updated",
                    phoneNumberUpdateFailed: "Phone number update failed",
                    phoneNumberUpdateError: "Phone number update error",
                }
            }
        },
        lng: language,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
